import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Home.css';

class Home extends Component {

    render() {

        return (
            <div className="home container">
                <div className="jumbotron">
                    <h1 className="display-4">Pok&eacute;squire!</h1>
                    <p className="lead">
                        Defeat your opponent's Pok&eacute;mon in battle quickly with your
                        trusty battle aide
                    </p>
                    <p className="lead">
                        <Link className="btn btn-primary btn-lg" to="/pokemon/" role="button">
                            Pok&eacute;mon
                        </Link>
                        &nbsp;
                        <Link className="btn btn-info btn-lg" to="/types/" role="button">
                            Types
                        </Link>
                    </p>
                </div>
                <h2>About</h2>
                <p>
                    Pok&eacute;squire has been built to help you quickly choose the right 
                    Pok&eacute;mon to send into battle, based on the type of your 
                    opponent's Pok&eacute;mon
                </p>

                <p>
                    I built the site to help me remember who to send out from my party when 
                    the game tells me "Mr Opponent is about to send out Suzzlepants!"
                
                    I can't always remember the types of every Poke&eacute;mon and what type
                    should be used and avoided
                </p>
                <p>
                    The site currently just holds Generation 1 data as I originally built the site as
                    a companion for the original Red and Blue games, but it works for Yellow and 
                    Pok&eacute;mon Let's Go Eevee/Pikachu as well, which is why I've revamped it.
                </p>
                <p>
                    There's a list of the first 151 Pokémon 
                    (if there is enough demand and traffic I can expand this to the other regions/generations)
                </p>

                <p>
                This site has no official partnership with or endorsement from Nintendo© and it 
                is not an official Pok&eacute;mon website.
                </p>

                <h2>Why is it called Pok&eacute;squire?</h2>
                <p>
                    It's like a squire, in battle, to a Knight. You're going into glourious battle 
                    and the app is your squire, there to assist you. 
                    <br />
                    Makes sense now, right? 
                </p>

                <h2>Who built the site? Who are you people?</h2>
                <p>
                    I'm just one people, you can talk to me here: 
                    Twitter: <a href="https://twitter.com/willshawmedia">@willshawmedia</a>
                    <br />
                    You can see what I've been coding here: 
                    Twitter: <a href="https://github.com/willshaw">Github.com/willshaw </a>
                    <br />
                    I'm around the internet, here and there, like a lot of people these days.
                </p>

                <h2>Cookies</h2>
                <p>
                    This site uses Google Analytics, which sets 2 cookies in your browser: 
                </p>
                <ul>
                    <li>_ga</li>
                    <li>_gat</li>
                </ul>
                <p>
                    I'm generally not interested in who you are or where you've come from, 
                    I'm not asking for any sensitive data and I'm not storing anything.
                    <br />
                    I'm using Google analytics to tell me what browsers people are using, 
                    which countries/languages I need to support and to find out which pages
                    people are using, arriving on and leaving from
                    <br />
                    All that information should help me to make a better site, that's all.
                    <br />
                    If you don't like the idea, turn off Cookies, the site will still work fine. 
                    Or, just don't use the site
                </p>
                <p>
                    Find out more about Cookies and how to turn them off here:
                    <br />
                    <a href="http://www.allaboutcookies.org/">All About Cookies</a>
                </p>

                <h2>How come it's free?!</h2>
                <p>
                    Becuase traffic is low (right now, just me using it) so I don't need to pay 
                    for much bandwidth.
                    <br />
                    I built it for myself and I don't want to charge myself a subscription or put ads in my own face
                    <br />
                    Plus most of the best sites in the world are anyway...
                </p>
            </div>
        );
    }
}

export default Home;
