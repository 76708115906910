import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import classNames from 'classnames';
import logo from './pokesquire.png';
import './App.css';
import Home from './Home';
import Types from './Types';
import Type from './Type';
import Pokemon from './Pokemon';
import Monster from './Monster';
import FourOhFour from './FourOhFour';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav: {
                open: false
            }
        };
    }    

    toggleNav = evt => {
        evt.persist();
        evt.preventDefault();
    
        // invert nav state
        this.setState({
            nav: {
                open: !this.state.nav.open
            }
        });

    }    

    render() {
        var navClasses = classNames({
            'collapse': !this.state.nav.open,
            'navbar-collapse' : true
          });

        return (
            <Router>
                <div className="App">
                    <nav className="navbar navbar-dark bg-dark">
                        <div className="container">
                            <Link className="pokesquire-title" to="/">
                                <img src={logo} width="30" height="30" alt="logo" />
                                <span className="red">Poké</span>
                                <span className="white">squire</span>
                            </Link>
                            <button onClick={this.toggleNav} className="navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={navClasses}>
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">
                                            Home <span className="sr-only">(current)</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/pokemon/">
                                            Pokémon
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/types/">
                                            Types
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <div className="App-content">
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/types/" component={Types} />
                        <Route path="/types/:type" component={Type} />
                        <Route exact path="/pokemon" component={Pokemon} />
                        <Route path="/pokemon/:pokemon" component={Monster} />
                        <Route component={FourOhFour} />
                    </Switch>
                    </div>

                    <footer>
                        <nav className="navbar fixed-bottom navbar-dark bg-dark">
                            <span>
                                Content &amp; design 
                                &copy; Peter Williamson
                                2019+
                            </span>
                            <span>
                                Pok&eacute;mon names and data 
                                &copy; Nintendo/Game Freak.
                                1995+ 
                            </span>
                        </nav>
                    </footer>
                </div>
            </Router>
        );
    }
}

export default App;
