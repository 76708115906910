import React, { Component } from 'react';
import { Link } from "react-router-dom";
import notamon from './notamon.png';
import './FourOhFour.css';

class FourOhFour extends Component {

    render() {

        return (
            <div className="fourohfour container">
                <h1>That was not supposed to happen!</h1>
                <p>
                    The web page you're trying to visit is about as real 
                    as this guy is a Pok&eacute;mon
                </p>
                <img 
                    className="notamon" 
                    alt="Something that is definitely not a Pok&eacute;mon, a large head with three eyes, arms for a mouth, a short three toed leg and a purple slug instead of the other leg" 
                    src={notamon} 
                />
                <p>
                    You've got a couple of choices, press the back button in your browser, 
                    or go back to the <Link to="/">home page</Link>
                </p>
            </div>
        );
    }
}

export default FourOhFour;
