import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Types.css';
import Breadcrumb from './Breadcrumb';
import gen_1 from './data/gen_1.json';

class Types extends Component {

    render() {

        // loop over types and output list
        let arr_type_names = Object.keys( gen_1.types );

        const type_links = arr_type_names.map((type) =>
            <Link key={type} className={'type_link ' + type} to={`/types/${type}`}>
                {type}
            </Link>
        );

        return (
            <div className="types container">
                <Breadcrumb active="Types" />
                {type_links}
            </div>
        );
    }
}

export default Types;
