import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Types.css';
import gen_1 from './data/gen_1.json';

class TypeDamage extends Component {

    render() {
        // damage lavels
        let arr_damage_levels = ['zero','half','double'];
        let skv_damage_level_names = {
            'zero': 'No effect',
            'half': 'Not very effective',
            'double': 'Super effective'
        }

        // get the type information
        let viewed_type_name = this.props.type;
        let viewed_type_info = gen_1.types[ viewed_type_name ];

        // loop over the damage levels and turn into a table
        // with title, 
        // damage dealt at this level
        // damage received at this level
        let damage_table = arr_damage_levels.map( ( level ) => {
            // default to no damage dealt or recieved
            let damage_dealt = 'n/a';
            let damage_received = 'n/a';

            // get the types affected by the type viewed on this page
            if( typeof viewed_type_info[ level ] !== 'undefined' ) {
                damage_dealt = viewed_type_info[ level ].map( ( type ) => 
                    <Link 
                        key={type} 
                        className={'type_link ' + type} 
                        to={`/types/${type}`}
                    >
                        {type}
                    </Link>
                )
            }

            // get the types that affect the type viewed on this page
            // e.g. those that cause damage to the viewed type
            let damaging_types = [];
            for( let damaging_type_name in gen_1.types ) {
                let damaging_type_info = gen_1.types[ damaging_type_name ];
                // if the current damaging type in the loop
                // does any damage at the current level,
                // and does that damage to our viewed type, we need to know
                if( 
                    typeof damaging_type_info[ level ] !== 'undefined' &&
                    damaging_type_info[ level ].indexOf( viewed_type_name ) !== -1
                ) {
                    damaging_types.push( damaging_type_name );
                }
            };

            // if we managed to find any types that damage our viewed type,
            // then we output those types
            if( damaging_types.length ) {
                damage_received = damaging_types.map( ( type ) => 
                    <Link 
                        key={type} 
                        className={'type_link ' + type} 
                        to={`/types/${type}`}
                    >
                        {type}
                    </Link>
                )
            }

            return <div key={level} className="row border-bottom">
                    <div className="col font-weight-bold border-right">
                        {skv_damage_level_names[ level ]}
                    </div>
                    <div className="col border-right">{damage_dealt}</div>
                    <div className="col">{damage_received}</div>
                </div> 
        });

        return (
            <div className="type_damage_table">
                <div className="row border-bottom">
                    <div className="col"></div>
                    <div className="col font-weight-bold">Dealt</div>
                    <div className="col font-weight-bold">Received</div>
                </div>
                {damage_table}
            </div>
        );
    }
}

export default TypeDamage;
