import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Pokemon.css';
import Breadcrumb from './Breadcrumb';
import gen_1 from './data/gen_1.json';

class Pokemon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pokemon: gen_1.pokemon,
            filtered_pokemon: gen_1.pokemon
        };

        this.filterList = this.filterList.bind(this);
    }   

    // set the filtered list of pokemon
    filterList( event ) {
        // set t
        this.setState({
            // filter the pokemon array by checking the input value
            // against the name, types and number of the pokemon
            // convert both the filter and pokemon name to lowercase to make it
            // case insensitive
            filtered_pokemon: this.state.pokemon.filter( ( pokemon ) => {
                let text_filter = event.target.value.toLowerCase();
                return pokemon.name.toLowerCase().match( text_filter ) 
                        || pokemon.types.join('').match( text_filter )
                        || pokemon.number.match( text_filter );
            })
        });
    }

    render() {
        // make a list of all the pokemon
        const pokemon_links = this.state.filtered_pokemon.map((pokemon, index) => {

            // get the types for this pokemon
            let pokemon_types = pokemon.types.map((type) => 
                <span 
                    key={type}
                    to={`/types/${type}`} 
                    className={`badge float-right ${type}`}
                >
                    {type}
                </span>
            )

            return <Link 
                key={index} 
                className={'pokemon_link ' + pokemon.name} 
                to={`/pokemon/${pokemon.name}`}
            >
                {pokemon.name}
                <span className="badge badge-secondary pokemon_number float-left">#{pokemon.number}</span>
                {pokemon_types}
            </Link>
        });

        return (
            <div className="pokemon container">
                <Breadcrumb active="Pok&eacute;mon" />
                <input 
                    className="form-control"
                    type="text" 
                    placeholder="Search"
                    onChange={this.filterList}
                />
                {pokemon_links}
            </div>
        );
    }
}

export default Pokemon;
