import React, { Component } from 'react';
// import { Link } from "react-router-dom";
// import './Types.css';
import Breadcrumb from './Breadcrumb';
import TypeDamage from './TypeDamage';

class Types extends Component {

    render() {
        // get the type information
        let viewed_type_name = this.props.match.params.type;

        return (
            <div className="types container">
                <Breadcrumb 
                    active={viewed_type_name} 
                    history={[{ 'href' :'/types', 'name' : 'types' }]} 
                />
                <h2>Damage</h2>
                <TypeDamage type={viewed_type_name} />
            </div>
        );
    }
}

export default Types;
