import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Monster.css';
import Breadcrumb from './Breadcrumb';
import TypeDamage from './TypeDamage';
import gen_1 from './data/gen_1.json';

class Monster extends Component {

    render() {
        let viewed_pokemon_name = this.props.match.params.pokemon;

        let skv_pokemon = gen_1.pokemon.filter( 
            (pokemon) => pokemon.name.toLowerCase().match( viewed_pokemon_name.toLowerCase() )
        )[ 0 ];

        // make a list of the types
        let types = skv_pokemon.types.map(
            ( type ) => <Link 
                            key={type}
                            to={`/types/${type}`}
                            className={`badge float-right type-badge ${type}`}
                        >
                            {type}
                        </Link>
        );

        // show the damage table, for a single typed pokemon this is easy,
        // for a multiple type pokemon we need to do some maths
        let damage_table = skv_pokemon.types.map( ( type ) => 
            <div className="pokemon_damage_table" key={type}>
                <h4 className={type}>{type} damage</h4>
                <TypeDamage type={type} />
            </div>
        )
                

        return (
            <div className="monster container">
                <Breadcrumb 
                    active={viewed_pokemon_name}
                    history={[{'name':'pokémon', href: "/pokemon"}]} 
                />
                <h3>
                    {viewed_pokemon_name}
                    <span className="badge badge-secondary pokemon_number float-left">
                        #{skv_pokemon.number}
                    </span>                    
                    {types}
                </h3>

                <div className="damage_table">
                    {damage_table}
                </div>
            </div>
        );
    }
}

export default Monster;
