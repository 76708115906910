import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Breadcrumb.css';

class Breadcrumb extends Component {

    render() {

        // build historic links, if we have any
        let history_links = '';
        if( 
            typeof this.props.history !== 'undefined' &&
            this.props.history.length
        ) {
            history_links = this.props.history.map( ( link ) => {
                return <li key={link} className="breadcrumb-item">
                    <Link to={link.href}>{link.name}</Link>
                </li>
            });
        }

        let active_link = <li className="breadcrumb-item active">
                            {this.props.active}
                        </li>;

        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {history_links}
                    {active_link}
                </ol>
            </nav>
        );
    }
}

export default Breadcrumb;
